var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"equipment-card__header-right-controls"},[_c('x-form',{class:_vm.zones > 1 && 'zones-form',style:({ 'grid-auto-flow': 'column' }),attrs:{"field-attrs":_vm.allFieldAttrs,"submit-button-state":_vm.$v.$invalid || _vm.invalidRateFlag,"submit-button-loading":_vm.useSubmitButtonLoading,"submit-error-text":_vm.submitError,"submit-button-outlined":true,"submit-button-text":"Передать показания"},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}},scopedSlots:_vm._u([{key:"textField",fn:function(ref){
var attrs = ref.attrs;
var fieldName = ref.fieldName;
return [(_vm.showField(fieldName))?_c('div',[(_vm.zones > 1)?_c('span',{staticClass:"secondary--text text--lighten-1 d-flex text-body-2 zone-label"},[_vm._v(" "+_vm._s(_vm.zone(fieldName))+" ")]):_vm._e(),_c('span',[_c('v-text-field',{staticClass:"form__input_size_sm pr-1",attrs:{"error-messages":_vm.getErrors(fieldName),"type":attrs.type,"rules":[function (v) { return (_vm.validateRate(fieldName, v)) || 'Неправильный формат'; }],"hide-details":"","required":_vm.fieldRequired(fieldName),"outlined":_vm.fieldProps.outlined,"single-line":_vm.fieldProps.singleLine,"suffix":"ед.","placeholder":_vm.getMask('0'),"disabled":_vm.disabled},on:{"input":function($event){return _vm.$v.values[fieldName].$touch()},"blur":function($event){return _vm.$v.values[fieldName].$touch()}},model:{value:(_vm.values[fieldName]),callback:function ($$v) {_vm.$set(_vm.values, fieldName, $$v)},expression:"values[fieldName]"}})],1),_c('span',{staticClass:"secondary--text text--lighten-1 d-flex text-body-2 pt-1"},[_c('span',[_vm._v(" Предыдущее показание")]),_c('span',{staticClass:"ml-auto"},[_vm._v(_vm._s(_vm.indication(fieldName)))])])]):_vm._e()]}}])}),_c('modal-dialog-for-indication',{attrs:{"show-dialog":_vm.show,"zones":_vm.zones,"zones-names":_vm.zonesNames,"rate-flag":_vm.rateFlag},on:{"agree":function () {
      this$1.show = false;
      _vm.sendData(1);
    },"disagree":_vm.closeAllDialogs}}),_c('x-notice',{attrs:{"show":_vm.useSuccessAlert}},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }